<template>
  <div class="roadmap" id='roadmap'>
    <div class="container">
      <div class="title">
        The&nbsp;
        <div class="color-text">MetaPhox</div>
        &nbsp;Roadmap
      </div>
      
      <div class="roadmap-block" v-for="(info, key) in roadmaps" :key="key">
        <img :src="getIcon(key + 1)" alt="" class='cover' />
        <div class="right">
          <div class="star-title">
            <div class="color-text">{{ info.title }}</div>
          </div>
          <div class="content">
            {{ info.content }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      roadmaps: [
        {
          title: "Frontier",
          content:
            'Completely fair launch and building a strong and healthy community is the first step we called "Frontier". All Metaphoxes will become custom playable Metaverse Avatars in Multi-Metaverses from Sandbox to Worldwide Webb.',
        },
        {
          title: "The Street",
          content:
            "The reveal of the token economy will boost the Metaphox-verse Ecosystem.\nIn the meantime, Metaphox staking will open, and a Diamond Hands Whitelist Launchpad will give the community an open and transparent way to get whitelists and join in new NFT sales.",
        },
        {
          title: "Metropolis",
          content:
            "A massive, multi-estate concrete jungle is built in The Sandbox, in which we create gaming and social experiences involving all Metaphox NFTs as well as the native token.",
        },
        {
          title: "Xanadu",
          content:
            'Metaphox will build its own Metaverse "Metaphox Xanadu". Metaphox Xanadu will include both the public world and customizable spaces. Foxers can not only play games but also spend time with the communities just as easy as real life.',
        },
      ],
    };
  },
  methods: {
    getIcon(name) {
      return require(`../assets/img/icon${name}.png`);
    },
  },
};
</script>
<style lang="scss">
.roadmap {
  display: flex;
  .title{
      display: flex;
      margin-bottom: 20px;
      font-size: 25px;
  }
  .sub-title {
    margin-bottom: 20px;
  }

  
  .roadmap-block {
      padding: 0px 20px;
    background-color: #f2992e;
    border-radius: 10px;
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    .cover{
        width: 160px;
    }
    .content {
        font-size: 20px;
  }
    .star-title {
      display: flex;
      align-items: center;
      .color-text {
        font-size: 30px;
        margin-bottom: unset;
      }
      img {
        width: 60px;
      }
    }
  }

  margin-bottom: 200px;
}
</style>
