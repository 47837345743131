<template>
  <div class="home">
    <!-- <div class="container"> -->
    <div class="mint-block">
      <div class="big-title">MetaPhox Genesis NFT</div>
      <div class="mint-info">{{minted}} / 9,999 MetaPhox Minted</div>
      <a href="#questions"
        ><div class="more-info">More Mint Information Here ></div></a
      >
      
      <claim-block/>
      <div class="img-block">
        <div class="img-cover">
          <img src="../assets/img/phox1.png" alt="" />
        </div>
        <div class="img-cover">
          <img src="../assets/img/phox2.png" alt="" />
        </div>
        <div class="img-cover">
          <img src="../assets/img/phox3.png" alt="" />
        </div>
      </div>
    </div>
    <div class="about-card" id="about">
      <div class="about-container">
        <div class="left">
          <div class="title">About MetaPhox</div>
          <div class="content">
            MetaPhox is the first NFT Whitelist Launchpad & A New Fox Xanadu in
            the Metaverse, built by the community. The MetaPhox Genesis NFT is a
            set of 9,999 non-fungible tokens (NFTs) comprised of hundreds of
            unique visual features and character bases. Each MetaPhox Genesis
            NFT will be the ticket to the coming NFT Whitelist Launchpad and the
            Fox Xanadu.
          </div>
          <div class="color-text">9,999 unique NFTs</div>
          <div class="color-text">Over 300 Unique Attributes</div>
        </div>
        <div class="right">
          <img class="moon" src="../assets/img/about.png" alt="" />
        </div>
      </div>
      <img class="nft-list-img" src="../assets/img/nfts.png" alt="" />
      <roadmap />
      <div class="community"></div>
      <div class="questions" id="questions">
        <div class="title">MetaPhox Frequently Asked Questions</div>
        <div
          class="q-block"
          v-for="(q, key) in q_list"
          :key="key"
          @click="q.open = !q.open"
        >
          <div class="q-title">
            {{ q.title }}
            <img src="../assets/img/arrow-down.svg" alt="" class="icon" />
          </div>
          <div class="q-content" :class="{ show: q.open }">{{ q.content }}</div>
        </div>
      </div>
      <div class="team" id="team">
        <div class="title">MetaPhox Team</div>
        <div class="sub-title">Say hello to the MetaPhox team!</div>
        <div class="team-list">
          <div class="card" v-for="(member, key) in team_list" :key="key">
            <div style="background-color: white" class="avatar">
              <img :src="getIcon(member.avatar)" alt="" />
            </div>
            <div class="name color-text">{{ member.name }}</div>
            <div class="intro">{{ member.intro }}</div>
          </div>
        </div>
      </div>
      <page-footer></page-footer>
    </div>
  </div>

  <!-- </div> -->
</template>
<script>
import Roadmap from "@/components/Roadmap";
import PageFooter from "@/components/Footer";
import ClaimBlock from '@/components/Claim'
import pageInfo from "@/assets/info.json"
import abi from "@/assets/abi.json";
import { mapState } from "vuex";
import config from "@/config"
export default {
  components: {
    Roadmap,
    PageFooter,
    ClaimBlock
  },
  data() {
    return {
      q_list:[],
      team_list:[],
      minted:0
    };
  },
  async mounted(){
    let self = this
    self.q_list = pageInfo.q_list
    self.team_list = pageInfo.team_list
  },
  watch:{
    "$store.state.web3": async function(){
      let self = this
      let contract = new self.web3.eth.Contract(abi,config.contractAddress)
    let amount = await contract.methods.totalSupply().call()
    self.minted = amount
    }
  },
  methods: {
    getIcon(name) {
      return require(`../assets/img/${name}.png`);
    },
  },
  computed:{
    ...mapState(["web3", "wallet_address"]),
    
},
};
</script>

<style lang="scss">
$basicFontSize: 30px;

.home {
  width: 100%;
  min-width: 1152px;
  .color-text {
    // background-image: linear-gradient(135deg, #f7f0ac, #acf7f0 50%, #f0acf7);
    // -webkit-background-clip: text;

    // background-clip: text;
    // -webkit-text-fill-color: transparent;
    font-weight: bold;
    font-family: "basicFont";
  }
  .container {
    width: 1152px;
    margin: 0px auto;
  }
  .mint-block {
    padding-top: 350px;
    text-align: center;
    background-image: linear-gradient(171deg, black, black);
    
    .big-title {
      color: #ffb1d2;
      font-size: 50px;
      line-height: 70px;
      font-weight: 700;
      font-weight: bold;
    }
    .mint-info {
      margin: 10px 0px;
      color: #b7bdf7;
      font-size: $basicFontSize;
      font-weight: bold;
      line-height: 35px;
      text-align: center;
    }
    .more-info {
      color: #acf7f0;
      font-weight: bold;
      margin-bottom: $basicFontSize;
    }
    .mint-cards {
      display: flex;
      justify-content: space-between;
      color: white;
      width: 700px;
      margin: 0px auto;

      .mint-card {
        position: absolute;
        bottom:0px;
        margin-bottom: $basicFontSize;
        .title {
          font-size: 23px;
          font-weight: bold;
          line-height: 60px;
        }
        .content {
          height: 50px;
          line-height: 50px;
          border: 2px solid hsla(0, 0%, 100%, 0.16);
          width: 213px;
          border-radius: 5px;
        }
      }
    }
    .img-block {
      display: flex;
      justify-content: space-between;
      width: 1170px;
      margin: 0px auto;
      .img-cover {
        max-width: 390px;
        display: flex;
        justify-content: center;

        img {
          max-width: 40vh;
        }
      }
    }
    .select-mint-amount {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 20px;

      .font {
        background-image: linear-gradient(
          135deg,
          #f7f0ac,
          #acf7f0 50%,
          #f0acf7
        );
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-weight: bold;
      }
      .block {
        cursor: pointer;
        margin-left: 15px;
        line-height: 30px;
        width: 30px;
        border-radius: 5px;
        border: 2px solid hsla(0, 0%, 100%, 0.16);
        &.selected{
          border-color: white;
        }
      }
    }
    .mint-btn {
      background-image: linear-gradient(75deg, #a664a6, #36489c , #8aced0);
      color: white;
      font-weight: bold;
      width: 500px;
      border-radius: 5px;
      height: 52px;
      line-height: 52px;
      margin: 0px auto;
      margin-bottom: 100px;
      font-size: 14px;
      cursor: pointer;
    }
  }
  .about-card {
    position: relative;

    .about-container {
      display: flex;
      width: 1150px;
      margin: 0px auto;
      margin-bottom: 100px;
    }
    background-image: linear-gradient(
        180deg,
        transparent,
        rgba(0, 0, 0, 0.2) 35%,
        rgba(86, 86, 86, 0.47) 63%,
        hsla(0, 0%, 96.5%, 0) 98%
      ),
      url(../assets/img/stars-bg.png), linear-gradient(111deg, black, black);
    background-position: 0px 0px, 50% 50%, 0px 0px;
    background-size: auto, contain, auto;
    background-repeat: repeat, repeat-y, repeat;
    padding: 230px 0px;
    .left {
      font-size: 20px;
      .title {
        display: flex;
        font-weight: bold;
        margin-bottom: 20px;
        font-size: 25px;
      }
      .content {
        font-weight: bold;
        margin-bottom: 20px;
        font-size: 20px;
      }
      .color-text {
        margin-bottom: 10px;
      }
    }
    .right {
      .moon {
        height: 410px;
      }
    }
    .nft-list-img {
      width: 100%;
    }

    .team {
      .title {
        font-size: 25px;
        font-weight: bold;
        display: flex;
        text-align: center;
        justify-content: center;
        margin-bottom: 10px;
      }
      .sub-title {
        text-align: center;
        margin-bottom: 40px;
      }
      .team-list {
        width: 720px;
        margin: 0px auto;
        display: flex;
        justify-content: space-between;
        .card {
          width: 160px;
          .avatar {
            img {
              width: 160px;
              height: 160px;
            }
            overflow: hidden;
            width: 160px;
            height: 160px;
            border-radius: 50%;
            margin-bottom: 20px;
          }
          .name {
            text-align: center;
            font-size: 20px;
            margin-bottom: 20px;
          }
          .intro {
            text-align: center;
          }
        }
      }
    }
    .questions {
      width: 720px;
      margin: 0px auto;
      margin-bottom: 100px;
      .title {
        font-size: 25px;
        font-weight: bold;
        display: flex;
        text-align: center;
        justify-content: center;
        margin-bottom: 50px;
      }
      .q-block {
        border: 1px solid white;
        padding: 20px;
        border-radius: 5px;
        margin-bottom: 20px;
        font-weight: bold;
        cursor: pointer;
        .q-title {
          font-size: 22px;
          display: flex;
          align-items: center;
          .icon {
            width: 30px;
            margin-left: auto;
          }
        }
        .q-content {
          height: 0px;
          overflow: hidden;
          transition: height 0.2s;
          white-space: break-spaces;

          &.show {
            margin-top: 20px;

            height: unset;
            transition: height 2s;
          }
        }
      }
    }
  }
}
@media (max-width: 520px) {
  .footer{
    margin-top: 100px;
  }
  .home {
    width: 100%;
    min-width: unset;
    box-sizing: border-box;
    overflow: hidden;

    .container,
    .mint-block,
    .mint-cards,
    .mint-btn,
    .about-container,
    .img-block,
    .questions {
      width: 100% !important;
    }
    .about-card {
      padding: 30px 10px;
      .roadmap {
          .roadmap-block {
            padding: 20px;
            .cover {
              display: none;
            }
            .star-title {
              display: flex;
              align-items: center;
              .color-text {
                font-size: 23px;
                margin-bottom: 20px;
                margin-left: 0px;
              }

              img {
                display: none;
              }
            }
            .content {
              margin-left: unset;
            }
          }
      }
      .about-container {
        display: block;
        .right {
          .moon {
            width: 100%;
            height: unset;
          }
        }
      }
      .team {
        .team-list {
          display: block;
          width: 100%;
          .card {
            width: 200px;
            margin: 0px auto;
            margin-bottom: 20px;
            .avatar {
              width: 160px;
              height: 160px;
              margin: 0px auto;
              margin-bottom: 20px;
            }
          }
        }
      }
    }

    .mint-block {
      padding: 150px 20px 0px 20px;
      height: 100vh;
      position: relative;
      box-sizing: border-box;
      .mint-cards {
        display: block;
        .mint-card {
          .content {
            width: 100%;
          }
        }
      }
      .img-block {
        position: absolute;
        bottom: 0px;
        left: 0px;
        .img-cover {
          min-width: 40px;
          img {
            max-width: 250px;
            width:180%;
          }
        }
      }
    }
    .rainbow {
      display: none;
    }
  }
}
</style>
